<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Centre Students - {{ centre_name }} (Total: {{ total_students }})</h4>
					</template>
					<template v-slot:headerAction>
						<!-- <router-link :to="{ name: 'students.add' }"><b-button variant="primary" class="btn mt-2 mr-1 btn-sm">Add Student</b-button></router-link> -->
					</template>
					<template v-slot:body>
						
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchStudents()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchStudents()" />
									</label>
								</div>							
								<div class="div-search mr-2">
									<select class="form-control" v-model="status" @change="fetchStudents()">
										<option value="">All</option>
										<option value="1">Published</option>
										<option value="2">Unpublished</option>
									</select>
								</div>
							</div>
						</div>
						<!----- Listing length and search ends ----->
						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th>Enrl. No</th>
										<th>Name</th>									
										<th>Contact</th>
										<th>Course</th>
										<th>Created on</th>
										<th>Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(students.length)">
									<tr v-for="student in students" :key="student.id">
										<td>{{ student.enrollment_number }}</td>
										<td>{{ student.name }}</td>							<td>
											{{ student.mobile }}<br>
											{{ student.email }}
										</td>
										<td>{{ student.course }}<br>{{ student.start }}-{{ student.end }}</td>
										<td>{{ student.created_at | formatDateTime }}</td>
										<td style="width: 200px;">
											<router-link :to="{ name: 'students.details', params: { id: student.id }}" class="btn btn-primary mt-2 mr-1 btn-sm">Details</router-link>

											<router-link :to="{ name: 'students.edit', params: { id: student.id }}" class="btn btn-primary mt-2 mr-1 btn-sm">Edit</router-link>

											<router-link :to="{ name: 'students.files', params: { id: student.id }}" class="btn btn-primary mt-2 mr-1 btn-sm">Files</router-link>

											<router-link :to="{ name: 'students.payments', params: { id: student.id }}" class="btn btn-primary mt-2 mr-1 btn-sm">Payments</router-link>

											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deactivateStudent(student)" v-if="student.status == 1">Unpublish</b-button>
											<b-button variant="success" class="btn mt-2 mr-1 btn-sm" @click="activateStudent(student)" v-else>Publish</b-button>
										</td>
									</tr>
									
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="6" class="text-center">No data</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchStudents(page.url + '&limit=' + listing_length + '&keyword=' + keyword + '&status=' + status)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
	export default {
		name: "Students",
		data() {
			return {
				students: [],				
				status: '',
				pagination: {},
				student_status: {
					id: "",
					status: "",
				},
				keyword: "",
				showsearch: false,
				listing_length: 10,
				token: "",
				errors: {},
				activate: false,
	         	deactivate: false,

	         	active_page_url: null,
			};
		},
		mounted() {},

		created() {
			this.fetchStudents();
		},
 
		methods: {
		
		    fetchStudents(page_url=null) 
		    {
	          let url = '/api/centres/students?limit=' + this.listing_length + '&keyword=' + this.keyword + "&status=" + this.status + "&centre_id=" + this.$route.params.id;
	          page_url = page_url || url;  
	          this.active_page_url = page_url;      	            
	          this.getToken();
	          this.$axios
	            .get(page_url,{headers: {Authorization: 'Bearer ' + this.token,}})
	            .then(res => {
	               var responseData = res.data.data;
	               this.students   = responseData.students.data; 
	               this.total_students = responseData.total_students;
	               this.centre_name = responseData.centre;
	               this.pagination = responseData.students.links	               
	            })
	            .catch(err => console.log(err));

	      },   

	      getToken() {
	         if(localStorage.token) {
	            this.token = localStorage.token;
	         } else {
	            this.$vToastify.error('Session expired, please login again');
	            this.$router.push({name: 'auth.login'})
	         }
	      },

          activateStudent(student) {
				this.activate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to publish this student?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		        .then(value => {
		            this.activate = value
		            if(this.activate){
						this.student_status.id = student.id;
						this.student_status.status = "1";
						this.updateStudentStatus();
					}
		        })
		         .catch(err => {
		            console.log(err);
		        })
			},

			deactivateStudent(student) {
				this.deactivate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to unpublish this student?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		         .then(value => {
		            this.deactivate = value
		            if(this.deactivate){
						this.student_status.id = student.id;
						this.student_status.status = "0";
						this.updateStudentStatus();
					}
         		})
         		.catch(err => {
           			console.log(err);
         		})
			},

			updateStudentStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/students/status?student_id=" + this.student_status.id + "&status=" + this.student_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.fetchStudents(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

		},
	};
</script>
